<template>
  <div class="input-container">
    <div :class="{ error: isError }" class="input-line">
      <div v-if="(gir?.length ?? 0) > 0" class="select-style">
        <select v-model="reactiveModelValue" @change="setData()">
          <option value="null">Selectionner une date:</option>
          <option v-for="girItem in gir" :key="girItem.id" :value="girItem.date.toString()">
            {{ transformerDateReview(girItem.date.toString()) }}
          </option>
        </select>
      </div>

      <input
        v-else
        :pattern="pattern"
        :placeholder="placeholder"
        :type="type"
        :value="modelValue"
        class="input"
        min="0"
        @blur="event => emit('on-blur', (event.target as HTMLInputElement).value)"
        @input="event => setData((event.target as HTMLInputElement).value)"
      />
      <span
        :style="{
          visibility: modelValue !== '' && suffix !== '' ? 'visible' : 'hidden'
        }"
        class="suffix"
        >{{ suffix }}</span
      >
    </div>
    <div v-show="isError" class="errors-container">
      <span v-for="err in errors" :key="err?.text" :class="['error', { valid: isValid }]">
        {{ err?.text }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SlugItem } from '@/lib/types/models/common';
import { GirDateAndPrice } from '@/lib/strapi-types/components/GirDateAndPrice';
import { transformerDateReview } from '@/lib/utils';

const { type, errors, modelValue, gir } = defineProps<{
  modelValue?: string | SlugItem | Date | number;
  suffix?: string;
  options?: SlugItem[];
  isLoading?: boolean;
  placeholder: string;
  pattern?: string;
  gir?: GirDateAndPrice[];
  type: 'text' | 'email' | 'password' | 'number' | 'tel' | 'date';
  errors?: ({ value?: boolean; text: string; validValue?: boolean } | undefined)[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue' | 'on-blur', value: string | SlugItem | Date): void;
}>();

const isError = $computed(() => {
  return errors?.some(err => err !== undefined);
});

const isValid = $computed(() => {
  return errors?.some(err => err?.validValue !== undefined);
});

const route = useRoute();
const reactiveModelValue = ref();
reactiveModelValue.value = route.query.departureDate;

function setData(payload?: string | SlugItem | Date): void {
  emit('update:modelValue', payload ?? reactiveModelValue.value);
}
</script>

<style lang="scss" scoped>
@use '$/colors.scss';
@use '$/misc.scss';
@use '$/shadows.scss';
@use '$/input.scss';
@use '$/breakpoints.scss';

@include input.input-date();
@include input.input-placeholder();

.input-container {
  flex-direction: column;
  width: 100%;

  .input-line {
    @include input.input-underline();

    align-items: center;
    justify-content: space-between;

    .input {
      @include input.input();

      width: 100%;
    }

    .select-style {
      width: 100%;
      height: 40px;
      background: white;
      border-bottom: 1px solid colors.$grey-700;

      select {
        width: 100%;
        background: transparent;
        border: none;
        box-shadow: none;

        &:focus {
          outline: none;
        }
      }
    }

    .suffix {
      font-size: 16px;
      color: white;

      @include breakpoints.mobile() {
        color: colors.$primary-black;
      }
    }
  }

  .errors-container {
    flex-direction: column;

    .error {
      @extend %error-label;
    }

    .valid {
      @extend %valid-label;
    }
  }
}
</style>
