import Gender from '@/lib/types/enums/Gender';
import { SlugItem } from '@/lib/types/models/common';
import { FormSeaErrorsModel, FormSEAModel } from '@/lib/types/models/formSEA';

export function useValidateErrors(): {
  validateField: (
    fieldKey: keyof FormSEAModel,
    value: string | Date | SlugItem | undefined | number | boolean,
    secondValue?: string | Date | SlugItem | undefined | number | boolean
  ) => void;
  errors: FormSeaErrorsModel;

  setFirstRenderError: (fieldKey: keyof FormSeaErrorsModel, value: boolean) => void;
} {
  function validateField(
    fieldKey: keyof FormSEAModel,
    value: string | Date | SlugItem | undefined | number | boolean | Gender,
    secondValue?: string | Date | SlugItem | undefined | number | boolean
  ): void {
    const today = new Date();
    const newValueDate = new Date(value as string);
    switch (fieldKey) {
      case 'destination':
        errors[fieldKey].isRequired.isError =
          (value as SlugItem).name === '' && (value as SlugItem).slug === '';
        break;
      case 'departureCityName':
        errors[fieldKey].isRequired.isError = value === undefined || (value as string).length === 0;
        break;
      case 'startDate':
        errors[fieldKey].isRequired.isError = isNaN(newValueDate.getTime());
        if (!errors[fieldKey].isRequired.isError)
          errors[fieldKey].isBeforeToday.isError =
            !isNaN(newValueDate.getTime()) && newValueDate < today;
        break;
      case 'duration':
        errors[fieldKey].isMoreThanZero.isError = parseInt(value as string) === 0;
        break;
      case 'gender':
        errors[fieldKey].isRequired.isError = value === undefined || (value as string).length === 0;
        break;
      case 'firstName':
        errors[fieldKey].isRequired.isError = value === undefined || (value as string).length === 0;
        break;
      case 'telephone':
        errors[fieldKey].isRequired.isError = value === undefined || (value as string).length === 0;
        if (!errors[fieldKey].isRequired.isError)
          errors[fieldKey].isRightFormat.isError = !/^[+]?[0-9 ]*$/.test(value as string);
        break;
      case 'indicatif':
        errors[fieldKey].isRequired.isError = value === undefined || (value as string).length === 0;
        if (!errors[fieldKey].isRequired.isError)
          errors[fieldKey].isRightFormat.isError = !/^\+[0-9]{2,3}$/.test(value as string);
        break;
      case 'lastName':
        errors[fieldKey].isRequired.isError = value === undefined || (value as string).length === 0;
        break;
      case 'email':
        errors[fieldKey].isRequired.isError = value === undefined || (value as string).length === 0;
        if (!errors[fieldKey].isRequired.isError)
          errors[fieldKey].isRightFormat.isError =
            !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value as string);
        break;
      case 'numberOfAdults':
        errors[fieldKey].isRequired.isError =
          parseInt(value as string) < 0 || isNaN(parseInt(value as string));
        if (!errors[fieldKey].isRequired.isError)
          errors.numberOfChildren.isRequired.isError =
            parseInt(value as string) < 0 || isNaN(parseInt(value as string));
        setFirstRenderError('numberOfChildren', false);
        break;
      case 'numberOfChildren':
        errors[fieldKey].isRequired.isError = parseInt(value as string) < 0;
        if (!errors[fieldKey].isRequired.isError) {
          errors.numberOfAdults.isRequired.isError = parseInt(secondValue as string) <= 0;
        }
        break;

      case 'budgetPerPerson':
        errors[fieldKey].isRequired.isError =
          parseInt(value as string) === 0 || isNaN(parseInt(value as string));
        break;
      case 'recommendationCode':
        errors[fieldKey].isValid.isError = false;
        break;
    }

    setFirstRenderError(fieldKey as keyof FormSeaErrorsModel, false);
  }

  const errors = reactive<FormSeaErrorsModel>({
    destination: {
      firstRenderError: true,
      isRequired: { text: 'Destination non renseignée', isError: false }
    },
    startDate: {
      firstRenderError: true,
      isRequired: { text: 'Date de départ non renseignée', isError: false },
      isBeforeToday: {
        text: "Date inférieure à la date d'aujourd'hui",
        isError: false
      }
    },
    departureCityName: {
      firstRenderError: true,
      isRequired: { text: 'Ville de départ non renseignée', isError: false }
    },
    duration: {
      firstRenderError: true,
      isMoreThanZero: {
        text: 'Durée de voyage inférieur à 0',
        isError: false
      }
    },
    recommendationCode: {
      firstRenderError: true,
      isValid: {
        text: 'Attention, le code saisi est incorrect et ne sera pas pris en compte à la validation.',
        isError: false,
        textSuccess: 'Vous devez valider votre code ou celui ci ne sera pas pris en compte.'
      }
    },
    numberOfAdults: {
      firstRenderError: true,
      isRequired: {
        text: 'Au moins 1 adulte',
        isError: false
      }
    },
    numberOfChildren: {
      firstRenderError: true,
      isRequired: {
        text: "Nombre d'enfants inférieur à 0",
        isError: false
      }
    },
    gender: {
      firstRenderError: true,
      isRequired: { text: 'Genre non renseigné', isError: false }
    },
    firstName: {
      firstRenderError: true,
      isRequired: { text: 'Prénom non renseigné', isError: false }
    },
    telephone: {
      firstRenderError: true,
      isRequired: { text: 'Numéro de téléphone non renseigné', isError: false },
      isRightFormat: { text: 'Format de numéro de téléphone invalide', isError: false }
    },
    lastName: {
      firstRenderError: true,
      isRequired: { text: 'Nom non renseigné', isError: false }
    },
    email: {
      firstRenderError: true,
      isRequired: { text: 'Adresse email non renseignée', isError: false },
      isRightFormat: { text: 'Format d‘adresse email invalide', isError: false }
    },
    budgetPerPerson: {
      firstRenderError: true,
      isRequired: { text: 'Budget non renseigné', isError: false }
    },
    indicatif: {
      firstRenderError: true,
      isRequired: { text: 'Indicatif non renseigné', isError: false },
      isRightFormat: { text: 'Veuillez saisir un indicatif au bon format', isError: false }
    },
    referrals: [
      {
        firstName: {
          firstRenderError: true,
          isRequired: { text: 'Prénom non renseigné', isError: false }
        },
        lastName: {
          firstRenderError: true,
          isRequired: { text: 'Nom non renseigné', isError: false }
        },
        email: {
          firstRenderError: true,
          isRequired: { text: 'Adresse email non renseignée', isError: false },
          isRightFormat: { text: 'Format d‘adresse email invalide', isError: false }
        }
      }
    ]
  });

  function setFirstRenderError(fieldKey: keyof FormSeaErrorsModel, value: boolean): void {
    if (fieldKey !== 'referrals') {
      errors[fieldKey].firstRenderError = value;
    }
  }

  return {
    validateField,
    errors,
    setFirstRenderError
  };
}
